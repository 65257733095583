<template>
    <div class="feedback-description">
        <p class="title m-b-8"><i class="material-icons">info</i>피드백이 왜 필요한가요?</p>
        <p class="desc">
            신뢰 가는 소개팅 문화를 만들기 위해<br />
            매너 없는 회원을 필터링하는 정보로 활용됩니다
        </p>
    </div>
</template>

<script>
export default {
    name: 'FeedbackDescription',
}
</script>

<style lang="scss" scoped>
.feedback-description {
    .title {
        display: flex;
        align-items: center;
        color: $blue-primary;

        @include f-medium;

        .material-icons {
            margin-right: 6px;
            font-size: 16px;
            color: $blue-primary;
        }
    }

    .desc {
        font-size: 13px;
        color: $grey-08;
        line-height: 18px;
    }
}
</style>
